/* Scroll Arrows */
.arrow-container {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(0deg);
}
.arrow-container:hover {
  cursor: pointer;
}
.arrow-container:hover .arrow {
  top: 50%;
}
.arrow-container:hover .arrow:before {
  transform: translate(-50%, -50%) rotateZ(-30deg);
}
.arrow-container:hover .arrow:after {
  transform: translate(-50%, -50%) rotateZ(30deg);
}
.arrow {
  position: absolute;
  left: 50%;
  transition: all 0.4s ease;
}
.arrowMobile {
  position: absolute;
  left: 50%;
  transition: all 0.4s ease;
}
.arrow:before, .arrow:after {
  transition: all 0.4s ease;
  content: '';
  display: block;
  position: absolute;
  transform-origin: bottom right;
  background: #5bba44;
  width: 4px;
  height: 50px;
  border-radius: 10px;
  transform: translate(-50%, -50%) rotateZ(-45deg);
}
.arrowMobile:before, .arrowMobile:after {
  transition: all 0.4s ease;
  content: '';
  display: block;
  position: absolute;
  transform-origin: bottom right;
  background: #5bba44;
  width: 4px;
  height: 30px;
  border-radius: 10px;
  transform: translate(-50%, -50%) rotateZ(-45deg);
}

.arrow:after {
  transform-origin: bottom left;
  transform: translate(-50%, -50%) rotateZ(45deg);
}
.arrow:nth-child(1) {
  opacity: 0.3;
  top: 35%;
}
.arrow:nth-child(2) {
  opacity: 0.6;
  top: 55%;
}
.arrow:nth-child(3) {
  opacity: 0.9;
  top: 75%;
}
.arrowMobile:after {
  transform-origin: bottom left;
  transform: translate(-50%, -50%) rotateZ(45deg);
}
.arrowMobile:nth-child(1) {
  opacity: 0.3;
  top: 35%;
}
.arrowMobile:nth-child(2) {
  opacity: 0.6;
  top: 55%;
}
.arrowMobile:nth-child(3) {
  opacity: 0.9;
  top: 75%;
}