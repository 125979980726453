.checkoutOrder{
    border-color: #5F9F75;
    border-radius: 4px;
    background-color: #5F9F75;
    position: fixed;
    bottom: 0;
    height:15%;
    width: 80%;
    margin-left: 20%;
  }
  .checkoutOrderWeb{
    border-color: #5F9F75;
    border-radius: 4px;
    background-color: #5F9F75;
    position: fixed;
    bottom: 0;
    height:22%;
    width: 100%;
  }
  .c1{
    padding: 13px;
  }
  .c1Web{
    padding: 0;
  }
  .payment{
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
  }

  .price{
    display: inline-flex;
    width: 100%
  }

  .total{
    padding: 5px;
    width: 40%;
    box-shadow:  0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    margin-left: 30px;
  }

  .button3{
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 20px;
    margin-top: 0;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-color: forestgreen;
  }
  .back{
    border-radius: 25px;
    color: white;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #5BBA44;
    text-decoration: none;
    border:0;
    font-size: 24px;
    width: 145px;
    height: 50px;
  }
  .backWeb{
    border-radius: 25px;
    color: white;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #5BBA44;
    text-decoration: none;
    border:0;
    font-size: 16px;
    width: 105px;
    height: 35px;
  }
  .backMobile{
    border-radius: 25px;
    color: white;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #5BBA44;
    text-decoration: none;
    border:0;
    font-size: 10px;
    width: 70px;
    height: 25px;
  }
  .continueWeb{
    border-radius: 25px;
    color: white;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #5BBA44;
    text-decoration: none;
    border:0;
    font-size: 16px;
    width: 120px;
    height: 35px;
  }

  .itemimage{
    position:absolute;
    bottom:0;
  }
  .text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.16;
    margin-top: 30px;
  }

  .textWeb{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.16;
    margin-top: 30px;
  }
  .promocode{
    border: 0.1px solid black;
    border-radius: 8px;
    box-shadow: none;
    font-size: 32px;
    padding: 20px;
    height: 52px;
    width: 76%;
    height:70px;
  }
 