body{
    padding: 0%;
    /* margin: 10px; */
    background-color: #fff;
}
Link{
    text-decoration: none;
}
div.image{
    background-image: url("../assets/background/fruits.jpg");
    background-position: center;
    height: 80%;
}
span.imageText{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    bottom:0;
    margin-left: 20px;
}
span.imageTextWeb{
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    /* position: absolute;
    bottom:0; */
    width: 100%;
    /* margin-left: 20px; */
    padding:20px 350px 20px 350px;
    display: inline-flex;
    padding-bottom: 0;
}
.counter{
    border-color: forestgreen;
}
.orders{
    height: 120px;
    width: 30%;
    text-align: center;
    /* margin: auto; */
    margin-top: 20px;
    border: 0.3px solid black;
  }
  .css-ahj2mt-MuiTypography-root{
    font-size: 32px;
  }