body {
  margin: 0;
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
Link {
  text-decoration: none;
}
.textdiningmode {
  position: absolute;
  margin: 0;
  width: 100%;
  top: 25%;
  text-align: center;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 0.16;
}

* {
  box-sizing: border-box;
}
.container {
  position: absolute;
  top: 25%;
  width: 100%;
  padding: 10%;
}
.img-container {
  float: left;
  width: 50%;
  padding: 5px;
}
.img-container-web {
  width: 50%;
  padding: 5px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.background {
  background-image: url("../assets/background/canopy_hawker_landing.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.background1 {
  background-image: url("../assets/background/BCHC_1_VECTOR.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.background2 {
  background-image: url("../assets/background/BCHC_2_VECTOR.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.background3 {
  background-image: url("../assets/background/BCHC_3_VECTOR.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.background4 {
  background-image: url("../assets/background/BCHC_4_VECTOR.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.background5 {
  background-image: url("../assets/background/BCHC_5_VECTOR.png");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  /* background-size: 100%; */
  z-index: -1;
  opacity: 0.2;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 52px;
}
.stall {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  color: #6d7278;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
}
.button1 {
  border-radius: 10px;
  background-color: rgb(140, 204, 45);
  color: white;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-color: forestgreen;
}

.button2 {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 100%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 120px;
  font-size: 26px;
}
.button2Mobile {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 100%;
  padding: 20px;
  border: 0;
  font-size: 16px;
  display: grid;
}
.button5 {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 100%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 85px;
  font-size: 28px;
}
.button5Web {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 100%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 85px;
  font-size: 28px;
}
.buttonCheckout {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 80%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 50px;
  font-size: 18px;
}
.checkVoucher {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 80%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 50px;
  font-size: 18px;
}
.checkVoucherMobile {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 100%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 30px;
  font-size: 12px;
}
.vouchercode {
  width: 70%;
  padding: 15px;
  border: 0.2px solid lightgrey;
  border-radius: 8px;
  outline: 0;
  font-size: 20px;
}
.voucherContainer {
  margin-bottom: 10px;
  background: #edededd4;
  border-radius: 8px;
  padding: 10px;
}
.vouchercodeMobile {
  width: 70%;
  padding: 15px;
  border: 0.2px solid lightgrey;
  border-radius: 8px;
  outline: 0;
  font-size: 12px;
}
.voucherContainerMobile {
  margin-bottom: 10px;
  background: #edededd4;
  border-radius: 8px;
  padding: 10px;
}
.buttonCheckoutMobile {
  border-radius: 10px;
  background-color: #5bba44;
  color: white;
  width: 80%;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  height: 30px;
  font-size: 12px;
}
.content {
  background-color: #fff;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid;
  border-color: forestgreen;
}
.order {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.16;
}
.orderDetails {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 42px;
}

.totalAmt {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  text-align: end;
  margin-right: 40px;
}

.checkout {
  border-radius: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  background: white;
}

.checkoutWeb {
  border-radius: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  background: white;
  text-align: center;
}

.payment {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.paymentCart {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin: 30px;
  /* height: 240px; */
  height: 170px;
}
.paymentCartWeb {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  /* margin: 30px; */
  /* height: 240px; */
  /* height: 170px; */
}
.checkoutCart {
  height: fit-content;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #c0e4b4;
}
.checkoutCartWeb {
  height: fit-content;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #c0e4b4;
  padding: 10px;
}
.successPayment {
  background-color: white;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  padding: 10%;
}
.promocode {
  padding: 5px;
  width: 40%;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  margin-left: 30px;
}
.date {
  border: 0.1px solid black;
  border-radius: 8px;
  box-shadow: none;
  font-size: 16px;
  padding: 10px;
  width: 20%;
  margin: 10px;
  margin-top: 0;
}
.time {
  border: 0.1px solid black;
  border-radius: 8px;
  box-shadow: none;
  font-size: 16px;
  padding: 10px;
  width: 20%;
  margin: 10px;
  margin-top: 0;
}
.serv ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  text-align: center;
}

.serv ul li {
  list-style: none;
  flex: 0 0 33.333333%;
}
.drawerWeb {
  width: fit-content;
  height: 185px;
  padding: 75px;
  padding-left: 30px;
  padding-right: 30px;
}

/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  top: 0;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 200px;
  width: 100%;
  /* border-radius: 10px; */
}
.slideWeb {
  display: inline-block;
  height: 800px;
  width: 100%;
  /* border-radius: 10px; */
}
/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #5bba44;
}
