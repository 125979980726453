  Link{
    text-decoration: none;
  }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
  .stall{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .button3{
    border-radius: 10px;
    color: darkgreen;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-color: #FFF;
  }

  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
  }
  ul.header {
    background-color: #111;
    padding: 0;
  }
  ul.header li a {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
  }
  .content {
    background-color: #FFF;
    padding: 15px;
    margin-top: 15px;
    border-radius: 8px;
    border: solid;
    border-color: forestgreen;
    height: 80%;
  }
  .content h3 {
    padding: 0;
    margin: 0;
    font-weight: bolder;
  }

  .content h2{
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .content p {
    padding: 0;
    font-size: large;
    margin-top: 0px;
    margin-bottom: 15px;
    color: #3A3B3C;
  }
  .content li {
    margin-bottom: 10px;
  }

  .netsInfo{
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .qrCode{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .timer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .order{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  .successOrder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .HpbLogo{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .HpbQrCode{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .HpbSlogan{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-top: 0;
    padding-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.16;
  }

  .orderDetails{
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 42px;
  }
  .orderDetailSub{
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color:#6D7278;
    font-weight: 500;
  }
  .orderDetailsWeb{
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  .orderDetailSubWeb{
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color:#6D7278;
    font-weight: 500;
  }
  .pricing{
    text-align: center;
  }
  .total{
   padding-top: 28px;
   font-size: larger;
   font-weight: 700;
  }
  .button4{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 70px;
  }
 .imgeffect:after{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%);
  border-radius: 50%;
  position: absolute;
  top: 0; left: 0;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.2);

}
#hover:hover{
  background-color: #fde7c7;
}
#hover-sub:hover{
  background-color: #fad98e;
}